import React, { FC } from 'react'
import styled from 'styled-components'
import { Box, Text, Stack } from '@chakra-ui/react'

const CardIcon = styled.span`
  & svg {
    margin: auto;
  }
`
const Card: FC<BoxGlobal.SocialProofBox> = ({ title, description, icon }) => (
  <Box display="flex">
    <CardIcon>{icon}</CardIcon>
    <Stack flex="60%" textAlign="left" paddingLeft="1rem" my="auto">
      <Text
        color="baseDark"
        fontFamily="Montserrat-SemiBold"
        fontSize="1.2rem"
        letterSpacing="0.6px"
        lineHeight="26px"
        marginBottom="0.3rem"
      >
        {title}
      </Text>
      <Text
        color="baseDark"
        fontFamily="Montserrat-Medium"
        fontSize="1rem"
        letterSpacing="0.2px"
        lineHeight="20.8px"
        marginTop="0!important"
      >
        {description}
      </Text>
    </Stack>
  </Box>
)

export default Card
